import { joinURL, withoutHost } from 'ufo';

interface CloudflareImageTransformations {
  anim?: boolean;
  background?: string;
  blur?: number;
  compression?: string;
  contrast?: number;
  dpr?: number;
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
  format?: 'auto' | 'avif' | 'webp' | 'jpeg' | 'baseline-jpeg' | 'json';
  gamma?: number;
  gravity?: string;
  height?: number;
  metadata?: 'keep' | 'copyright' | 'none';
  onerror?: 'redirect';
  quality?: number;
  rotate?: number;
  sharpen?: number;
  width?: number;
}

interface CloudflareImageAliases {
  f?: CloudflareImageTransformations['format'];
  g?: CloudflareImageTransformations['gravity'];
  h?: CloudflareImageTransformations['height'];
  q?: CloudflareImageTransformations['quality'];
  w?: CloudflareImageTransformations['width'];
}

type CloudflareImageOptions = CloudflareImageTransformations & CloudflareImageAliases;

export function useCloudflareImage(
  resource: MaybeRefOrGetter<string>,
  options: CloudflareImageOptions = {}) {
  const value = toValue(resource);

  const transformations = Object.entries(options).map(([k, v]) => `${k}=${v}`).join(',');

  if (!transformations.length) {
    return value;
  }

  return joinURL('https://static.kygunco.com', 'cdn-cgi/image', transformations, withoutHost(value));
}
