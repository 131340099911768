export enum OrderType {
  Notify = 'Notify',
  Backorder = 'Backorder',
  Preorder = 'Preorder',
}

export enum ShippingClass {
  Flat = 'Flat',
  Flat25 = 'Flat25',
  Free = 'Free',
  Ground = 'Ground',
  Hazmat = 'Hazmat',
  InStore = 'InStore',
  Notify = 'Notify',
  Virtual = 'Virtual',
}

export enum Vendor {
  KyGunCo = 'KyGunCo',
  SportsSouth = 'SportsSouth',
  Lipseys = 'Lipseys',
  Zanders = 'Zanders',
  RsrGroup = 'RsrGroup',
}

export interface PaginatedCollection<T> {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  nextPage: number | null;
  prevPage: number | null;
  lastPage: number;
  items: T[];
}

export interface InStoreAvailabilityResponse {
  locationId: string;
  locationName: string | null;
  locationAddress: BaseAddress;
  isAvailable: boolean;
}

export interface BaseAddress {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  company: string | null;
  street: string;
  street2: string | null;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

export interface AddressResponse extends BaseAddress {
  id: number;
}

export interface FileResponse {
  id: string;
  title: string | null;
  type: string;
  url: string;
  size: number | null;
  width: number | null;
  height: number | null;
  duration: number | null;
}

export interface VariationResponse {
  id: number;
  name: string;
  identifier: string | null;
  dimensions: { label: string; code: string }[];
  minOrderQuantity: number | null;
  maxOrderQuantity: number | null;
  regularPrice: number;
  price: number;
  isOnSale: boolean;
  isInStock: boolean;
  inStoreAvailability: InStoreAvailabilityResponse[];
}

export type MinimumAdvertisedPricePolicy =
  | 'Click'
  | 'Email'
  | 'AddToCart'
  | 'Checkout';

export type Condition = 'New' | 'Used' | 'Blemished' | 'Refurbished';

export type RelationType = 'XSell' | 'Upsell';

export interface ProductResponse {
  id: string;
  name: string;
  description: string | null;
  longDescription: string | null;
  shortDescription: string | null;
  slug: string;
  style: string;
  mpn: string;
  upc: string | null;
  condition: Condition;
  minCartQuantity: number | null;
  maxCartQuantity: number | null;
  imageUrl: string | null;
  thumbnailUrl: string | null;
  placeholderUrl: string | null;
  metaTitle: string | null;
  metaDescription: string | null;
  regularPrice: number;
  price: number;
  isOnSale: boolean;
  isInStock: boolean;
  quantity: number;
  orderType: OrderType | null;
  shippingClass: ShippingClass | null;
  vendor: Vendor;
  inStoreAvailability: InStoreAvailabilityResponse[];
  averageScore: number;
  reviewCount: number;
  videoCount: number;
  rebateCount: number;
  minimumAdvertisedPriceApplies: boolean;
  minimumAdvertisedPricePolicy: MinimumAdvertisedPricePolicy | null;
  minimumAdvertisedPriceText: string | null;
  isFirearm: boolean;
  isAmmunition: boolean;
  isUsed: boolean;
  isQualifiedProfessional: boolean;
  isClass3: boolean;
  isAge21Restricted: boolean;
  isHazmat: boolean;
  isInStore: boolean;
  isFreeShipping: boolean;
  isDropshipable: boolean;
  manufacturer: ManufacturerResponse;
  dimLabel1: string | null;
  dimLabel2: string | null;
  dimLabel3: string | null;
  variations: VariationResponse[];
  assets: FileResponse[];
  attributes: { code: string; label: string; value: string }[];
  related: { id: string; type?: RelationType | null }[];
}

export interface ReviewResponse {
  id: number;
  isAnonymous: boolean;
  score: number;
  title: string | null;
  content: string | null;
  authorLocation: string | null;
  authorName: string | null;
  created: string;
  updated: string;
}

export interface ManufacturerResponse {
  id: number;
  name: string;
  slug: string;
  image: FileResponse;
  description: string | null;
  metaTitle: string | null;
  metaDescription: string | null;
  shortDescription: string | null;
  rebateCount: number;
}

export interface RebateResponse {
  id: number;
  title: string;
  url: string | null;
  description: string | null;
  file: FileResponse | null;
  startDt: string;
  endDt: string;
}

export interface CategoryResponse {
  id: number;
  parentId: number | null;
  name: string;
  slug: string;
  image: FileResponse | null;
  description: string | null;
  metaTitle: string | null;
  metaDescription: string | null;
  shortDescription: string | null;
  children: CategoryResponse[];
}

export interface GroupResponse {
  id: number;
  name: string;
  slug: string;
  metaTitle: string | null;
  metaDescription: string | null;
  shortDescription: string | null;
}

export interface AuthenticateResponse {
  accessToken: string;
  refreshToken: string;
}

export interface RefreshResponse {
  accessToken: string;
}

export interface UserResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  twoFactorEnabled: boolean;
  phoneNumberConfirmed: boolean;
  phoneNumber: string;
  emailConfirmed: boolean;
  cartId: string;
  created: string;
  updated: string;
}

export interface ProblemDetails {
  type: string;
  title: string;
  status: number;
  detail?: string;
  errors?: Record<string, string[]>;
}

export interface EcommerceResponse<T> extends Response {
  _data?: T;
}

export type ProductSummary = Pick<
  ProductResponse,
  | 'id'
  | 'name'
  | 'description'
  | 'longDescription'
  | 'shortDescription'
  | 'slug'
  | 'mpn'
  | 'upc'
  | 'imageUrl'
  | 'thumbnailUrl'
  | 'placeholderUrl'
  | 'isFirearm'
  | 'isAmmunition'
  | 'isUsed'
  | 'isQualifiedProfessional'
  | 'isClass3'
  | 'isAge21Restricted'
  | 'isHazmat'
  | 'isInStore'
  | 'isFreeShipping'
>;

export interface AuthenticateRequest {
  username: string | null;
  password: string | null;
  cartId?: string | null;
}

export interface RegisterRequest {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  password: string | null;
  confirmPassword: string | null;
  cartId?: string | null;
}

export interface CartItem {
  quantity: number;
  productId: string;
  variationId?: number | null;
}

export interface CartItemResponse extends CartItem {
  id: number;
  thumbnailUrl: string;
  placeholderUrl: string;
  name: string;
  style: string;
  price: number;
  total: number;
  isVendorItem: boolean;
  isFirearm: boolean;
  isAmmunition: boolean;
  isUsed: boolean;
  isQualifiedProfessional: boolean;
  isClass3: boolean;
  isAge21Restricted: boolean;
  isHazmat: boolean;
  isVirtual: boolean;
  isFreeShipping: boolean;
  canShowPrice: boolean;
}

export interface CartResponse {
  id: string;
  subTotal: number;
  items: CartItemResponse[];
}

export type NotificationType = 'Email' | 'Sms';

export type NotificationEvent = 'Stock' | 'Price';

export interface NotificationRequest {
  to: string | null;
  productId: string;
  type: NotificationType;
  event: NotificationEvent;
}

export interface NotificationResponse {
  id: number;
  destination: string;
  type: NotificationType;
  event: NotificationEvent;
  sent: string | null;
  created: string;
  updated: string;
  product: ProductSummary;
}

export interface ProductQuoteRequest {
  email: string | null;
  variationId?: number | null;
}

export interface DocumentResponse {
  id: number;
  type: string;
  notes: string | null;
  mimeType: string;
  created: string;
}

export interface OrderTotalResponse {
  description: string;
  total: number;
}

export interface OrderItemResponse {
  id: number;
  quantity: number;
  productId: string;
  variationId: number | null;
  thumbnailUrl: string | null;
  name: string;
  style: string;
  price: number;
  total: number;
  review: ReviewResponse | null;
}

export type Carrier = 'Fedex' | 'Ups' | 'Usps' | 'Speedee';

export interface ShipmentResponse {
  id: number;
  trackingNumber: string;
  carrier: Carrier;
  created: string;
}

export interface TransactionResponse {
  id: number;
  amount: number;
  source: 'Online' | 'InStore';
  type: 'Cash' | 'Check' | 'CreditCard' | 'StoreCredit' | 'Financing' | 'Ach';
  reference: string | null;
  created: string;
}

export interface OrderResponse {
  id: string;
  email: string;
  isPickup: boolean;
  isClass3: boolean;
  isVirtual: boolean;
  subTotal: number;
  grandTotal: number;
  status: 'Pending' | 'OnHold' | 'Processing' | 'Cancelled' | 'Complete';
  statusMessage: string | null;
  customerNotes: string | null;
  isLayaway: boolean;
  isPayable: boolean;
  minimumAmount: number;
  balance: number;
  allowedTransactionTypes: string[];
  billingAddress: BaseAddress;
  shippingAddress: BaseAddress;
  totals: OrderTotalResponse[];
  items: OrderItemResponse[];
  shipments: ShipmentResponse[];
  transactions: TransactionResponse[];
  created: string;
}

export type CheckoutStep =
  | 'BillingAddress'
  | 'ShipDestination'
  | 'ShippingAddress'
  | 'PickupLocation'
  | 'FflDealer'
  | 'PaymentOptions'
  | 'Submit';

export type AdjustmentType =
  | 'Shipping'
  | 'Insurance'
  | 'AdultSignature'
  | 'Tax'
  | 'Hazmat'
  | 'CreditCard'
  | 'StoreCredit';

export type TransactionType =
  | 'Cash'
  | 'Check'
  | 'CreditCard'
  | 'StoreCredit'
  | 'Financing'
  | 'Ach';

export type FulfillmentType =
  | 'CustomerDirect'
  | 'CustomerDropship'
  | 'DealerDirect'
  | 'DealerDropship'
  | 'CustomerPickup';

export interface AdjustmentResponse {
  type: AdjustmentType;
  description: string | null;
  total: number;
}

export interface WarehouseResponse {
  id: string;
  name: string;
  phoneNumber: string;
  company: string | null;
  street: string;
  street2: string | null;
  city: string;
  state: string;
  postcode: string;
  country: string;
}

export interface StoreCreditResponse {
  code: string;
  original: number;
  balance: number;
  debit: number;
}

export interface StoreCreditRequest {
  code: string;
  amount: number;
}

export interface QuoteOrderResponse {
  type: FulfillmentType;
  origin: WarehouseResponse;
  destination: BaseAddress;
  items: CartItemResponse[];
  totals: AdjustmentResponse[];
  subTotal: number;
  grandTotal: number;
}

export interface FinancingApplication {
  id: string;
  url: string;
}

export interface QuoteRequest {
  isInsured: boolean;
  isLayaway: boolean;
  isPickup: boolean;
  paymentMethod: TransactionType;
  shippingAddressId?: number | null;
  billingAddressId?: number | null;
  fflDealerId?: number | null;
  pickupLocationId?: string | null;
  financingApplicationId?: string | null;
  storeCredits: StoreCreditRequest[] | null;
}

export interface QuoteResponse {
  requiresFflDealer: boolean;
  requiresShippingAddress: boolean;
  isLayawayAvailable: boolean;
  isPickupAvailable: boolean;
  balance: number;
  layawaySubTotal: number;
  subTotal: number;
  grandTotal: number;
  allowedTransactionTypes: TransactionType[];
  totals: AdjustmentResponse[];
  orders: QuoteOrderResponse[];
  pickupLocations: WarehouseResponse[];
  usedStoreCredits: StoreCreditResponse[];
  financing: FinancingApplication | null;
}

export interface CreditCardRequest {
  cardNumber: string;
  securityCode: string;
  expirationYear: number;
  expirationMonth: number;
}

export interface FinancingRequest {
  applicationId: string;
}

export interface CheckoutRequest {
  isInsured: boolean;
  isLayaway: boolean;
  isPickup: boolean;
  customerNotes?: string | null;
  paymentMethod: TransactionType;
  billingAddressId: number;
  shippingAddressId?: number;
  fflDealerId?: number;
  pickupLocationId?: string;
  sessionId: string;
  creditCard?: CreditCardRequest;
  financing?: FinancingRequest;
  storeCredits: StoreCreditRequest[];
}

export interface OrderPaymentRequest {
  amount: number;
  paymentMethod: TransactionType;
  billingAddressId: number;
  creditCard?: CreditCardRequest;
}

export interface FflDealerResponse {
  id: number;
  companyName: string;
  shippingAddress: BaseAddress;
  phoneNumber?: string | null;
  companyUrl?: string | null;
}

export type CreditCardType =
  | 'american-express'
  | 'diners-club'
  | 'discover'
  | 'elo'
  | 'hiper'
  | 'hipercard'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'mir'
  | 'unionpay'
  | 'visa';

export interface CreditCard {
  number: string | null;
  code: string | null;
  type: CreditCardType | null;
  expMonth: number | null;
  expYear: number | null;
  isValid: boolean;
}

export interface ReviewRequest {
  isAnonymous: boolean;
  orderItemId: number;
  score: number;
  title: string;
  content: string;
}
