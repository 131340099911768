import { createInjectionState } from '@vueuse/core';
import { runSequentialPromises } from 'quasar';
import { useRouteQuery } from 'vue-use-route-query';
import { Vendor, type ProductResponse } from '~/types/ecommerce';

const state = (value: MaybeRefOrGetter<ProductResponse>) => {
  const product = toValue(value);
  const cartStore = useCartStore();
  const { $bus } = useNuxtApp();

  const {
    ageDialog,
    qpDialog,
    partnerDialog,
    soDialog,
    notifyDialog,
    quoteDialog,
  } = useDialogs();

  const quantity = ref(1);

  const add = async () => {
    const confirmed = await confirm();

    if (!confirmed) {
      return;
    }

    await cartStore.add({
      productId: product.id,
      quantity: quantity.value,
      variationId: variation.value?.id,
    });

    $bus.emit('product:add', product, variation.value, quantity.value);

    quantity.value = 1;
  };

  const confirm = async (): Promise<boolean> => {
    const dialogs: (() => Promise<void>)[] = [];

    if (product.isAmmunition) {
      dialogs.push(() => ageDialog());
    }

    if (product.isQualifiedProfessional) {
      dialogs.push(qpDialog);
    }

    if (product.vendor != Vendor.KyGunCo) {
      dialogs.push(partnerDialog);
    }

    if (product.orderType == 'Preorder' || product.orderType == 'Backorder') {
      dialogs.push(() =>
        soDialog(product.orderType as 'Preorder' | 'Backorder'),
      );
    }

    if (!dialogs.length) {
      return true;
    }

    try {
      await runSequentialPromises(dialogs);
    }
    catch {
      return false;
    }

    return true;
  };

  const notify = () => notifyDialog({ productId: product.id }).catch(noop);

  const quote = () =>
    quoteDialog({
      productId: product.id,
      variationId: variation.value?.id,
    }).catch(noop);

  const dimensions = computed(
    () =>
      [product.dimLabel1, product.dimLabel2, product.dimLabel3].filter(
        code => !!code,
      ) as string[],
  );

  const variation = computed(() =>
    product.variations.find(v => v.identifier == dimCodes.value.join('-')),
  );

  const dimCodes = useRouteQuery<string[]>('option', [], {
    fromQuery: query => query.split('-'),
    toQuery: value => value?.join('-'),
  });

  const options = computed(() => {
    return dimensions.value.map((dimension) => {
      const items = [...new Set(product.variations.map(v =>
        v.dimensions.filter(d => d.label === dimension)
          .map(d => d.code))
        .flat())];

      return {
        label: dimension,
        items: items.every(i => isNumeric(i))
          ? items.sort((a, b) => Number(a) - Number(b))
          : items,
      };
    });
  });

  const calibers = computed(
    () =>
      product.attributes
        .find(a => a.code == 'caliber')
        ?.value?.split(' - ')
        ?.map(c => c.trim()) ?? [],
  );

  const model = computed(
    () => product.attributes.find(a => a.code == 'model')?.value,
  );

  const quoted = computed(() => 'cart' in useRoute().query === true);

  return {
    product,
    variation,
    add,
    confirm,
    notify,
    quote,
    quoted,
    quantity,
    dimensions,
    options,
    dimCodes,
    attributes: {
      calibers,
      model,
    },
  };
};

const [useProvideProduct, useProduct] = createInjectionState(state);

export { useProvideProduct };
export { useProduct };
